<template>
    <div>
        <b-card>
            <MaintenanceRequestsFilters
                :perPage="perPage"
                :perPageOptions="perPageOptions"
                :searchQuery="searchQuery"
                :requests="listRequest"
                :isLoadingData="isLoadingRequests ||isUpdatingRequest || isLoadingInitialData"
                @change-per-page="changePerPage"
                @search-in-table-records="searchInTableRecords"
                @look-per-filter="filteredSearch"
                @reset-filter="resetTable"
            />
            <b-table
                v-if="filteredRequests.length > 0"
                :items="filteredRequests"
                :fields="tableColumns"
                responsive
                small
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                :sort-desc.sync="isSortDirDesc"
                :busy.sync="isLoadingRequests"
                primary-key="id"
                show-empty
                class="position-relative"
            >

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> Cargando...</strong>
                  </div>
                </template>
                <template #cell(reason)="row">
                    <b-link
                        v-if="can('fivesclub_web_owners_show_maintenance_request_view')"
                        :to="{ name: 'maintenance-request-detail', params: { id: row.item.id} }"
                        class="font-weight-bolder link-text"
                    > {{ row.item.reason }}
                    </b-link>
                    <span v-else>{{ row.item.reason }}</span>
                </template>
                <template #cell(actions)="row">
                    <b-dropdown toggle-class="p-0" no-caret right v-if="row.item.status != 2 && row.item.status != 3 && row.item.status != 4" :disabled="isUpdatingRequest">
                        <template #button-content>
                            <b-button variant="primary" size="sm">
                                <b-spinner v-if="isUpdatingRequest" small />
                                <feather-icon v-else icon="MoreHorizontalIcon" size="16" class="align-middle" style="color: white" />
                            </b-button>
                        </template>
                        <b-dropdown-item @click="chooseResponse(row.item)" v-if="can('fivesclub_web_owners_show_maintenance_request_auth_button')">
                            <feather-icon icon="CheckIcon" />
                            <span class="align-middle ml-50"> Autorizar</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="goToReject(row.item)" v-if="can('fivesclub_web_owners_show_maintenance_request_reject_button') && row.item.status != 4">
                            <feather-icon icon="XIcon" />
                            <span class="align-middle ml-50"> Rechazar</span>
                            <ModalRejectRequest :requestData="row.item" @choose-response="setResponse"/>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

                <template #cell(statusname)="row">
                    <b-badge pill :class="badgeStatus(row.item)">{{row.item.statusname}}</b-badge>
                </template>
            </b-table>

            <div class="text-center my-2" v-else-if="isLoadingRequests">
                <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                <strong> Cargando...</strong>
            </div>

            <div v-else>
                <b-alert :show="notFoundData" variant="warning" class="p-1 text-center mt-2">No hay registros</b-alert>
            </div>

            <PaginationTable
                class="mt-1"
                :currentPage="currentPage"
                :totalRows="listRequest.length"
                :perPage="perPage"
                @change-page="changePage"
                v-show="listRequest.length"
            />
        </b-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"
import { sweetAlert, showAlertMessage } from '@/helpers/helpers'
import MaintenanceRequestsFilters from "@/modules/fivesClub/components/catalogs/ownersWebsite/MaintenanceRequestsFilters"
import PaginationTable from "@/modules/fivesClub/components/catalogs/ownersWebsite/PaginationTable"
import ModalRejectRequest from "@/modules/fivesClub/components/catalogs/ownersWebsite/ModalRejectRequest"

export default {
    mixins: [acl],
    directives: { Ripple, },
    components: {
        ModalRejectRequest,
        MaintenanceRequestsFilters,
        PaginationTable
    },
    async created(){
        await this.init()
    },
    data(){
        return {
            isLoadingInitialData: false,
            isLoadingRequests: false,
            isUpdatingRequest: false,
            listRequest: [],
            tableColumns: [
                { key:'resortsname', class:'text-center', tdClass: 'p-1', label: 'Resort'},
                { key:'housingnumber', class:'text-center', tdClass: 'p-1', label: 'Vivienda'},
				{ key:'ownerName', class:'text-center', tdClass: 'p-1', label: 'Propietario'},
				{ key:'reason', class:'text-center', tdClass: 'p-1', label: 'Motivo'},
				{ key:'startDate', sortable: true, class:'text-center', tdClass: 'p-1', label: 'Fecha de Inicio'},
				{ key:'endDate', sortable: true, class:'text-center', tdClass: 'p-1', label: 'Fecha Fin'},
				{ key:'providerName', class:'text-center', tdClass: 'p-1', label: 'Proveedor'},
				{ key:'statusname', class:'text-center', tdClass: 'p-1', label: 'Estatus' },
				{ key:'actions', class:'text-center', tdClass: 'p-1', label: 'Acciones', class:'text-center'}
            ],
			filterOn: ["housingnumber", "ownerName", "reason", "providerName" ],
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            notFoundData: false,
            isSortDirDesc: true,
        }
    },
    computed: {
        // status 4, es rechazada
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
		filteredRequests(){
			const items = this.listRequest
			return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
		},
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["fetchMaintenanceRequests", "fetchMaintenanceRequestsExcel", "setMaintenanceResolution", "getInitialContentForTheFivesVacations"]),
        ...mapMutations('start',['setHotels']),
        async init(){
            this.isLoadingInitialData = true
            // const requests = await this.fetchMaintenanceRequests()
            // requests.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
            // this.listRequest = requests
            
            const { hotels } = await this.getInitialContentForTheFivesVacations()
            if( !this.hotels.length ) this.setHotels(hotels)

            this.isLoadingInitialData = false
        },
        async chooseResponse(request){
            const swalert = {
                title: `Autorizando mantenimiento para la vivienda ${request.housingnumber}`,
                message: "¿Desea continuar?",
                icon: 'success',
                confirmButton: "Continuar",
                cancelButton: "Cancelar",
            }

            const {isConfirmed} = await sweetAlert(swalert)
            if ( isConfirmed ) await this.setResponse({request, choice: 2})

        },
        async setResponse(payload) {
            const {request, choice} = payload
            this.isSavingControlAccessData=true
            // console.log({id: request.id, idUser:this.user.idUser, resolution: choice, reviewNotes: request.reviewNotes});
            const respuesta = await this.setMaintenanceResolution({id: request.id, idUser:this.user.idUser, resolution: choice, reviewNotes: request.reviewNotes})
            if(!!respuesta) {
                showAlertMessage(`Ok`, 'InfoIcon', `Se ${choice == 2 ? 'autorizó':"rechazó"} la solicitud correctamente`, 'success', 4000, 'bottom-right')
                await this.init()
            }
            this.isSavingControlAccessData=false
        },
        goToReject(item) {
            this.$root.$emit("bv::show::modal", "modal-reject-maintenance-request-" + item.id)
        },
        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },
        searchInTableRecords(searchQuery){
            this.searchQuery = searchQuery
        },
        async filteredSearch(payload){
            this.isLoadingRequests = true
            if (payload.excel) await this.fetchMaintenanceRequestsExcel(payload)
            else {
                const requests = await this.fetchMaintenanceRequests(payload)
                requests.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
                this.listRequest = requests
                this.notFoundData = requests?.length == 0
            }
            this.isLoadingRequests = false
        },
        resetTable(){
            this.listRequest = []
            this.notFoundData = false
        },
        badgeStatus(request){
			const { status } = request

            let variant =  'bg-success'
			if(status === '1' ) variant = 'bg-info'
			if(status === '2' ) variant = 'bg-success'
			if( status === '3' ) variant = 'bg-danger'
			if( status === '4' ) variant = 'bg-warning'

			return variant
		},
    },
};
</script>

<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}

.table-text{
    font-size: 0.8rem;
}
.link-text{
    text-decoration: underline solid 1.5px !important;
}
</style>
