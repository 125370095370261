<template>
  <b-modal
    size="sm"
    :id="'modal-reject-maintenance-request-' + requestData.id"
    :title="'Rechazando mantenimiento a la propiedad ' + requestData.resortsname + ' | ' + requestData.housingnumber"
    ok-only
    hide-footer
  >
    <b-card-text>
      <ValidationObserver ref="rejectMaintenanceRequest" v-slot="{ invalid }">
        <b-row class="mb-2">
          <b-col md="12">
            <ValidationProvider rules="required|max:500" name="nota de rechazo">
              <b-form-group label="Nota de rechazo" slot-scope="{ valid, errors }">
                <b-form-textarea
                  v-model="notes"
                  placeholder="Escribe la nota de rechazo"
                  rows="3"
                  :state="(errors.length == 0 && valid) ? null : false"
                  />
                  <!-- :maxlength="500" -->
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <div class="text-right">
              <b-button
                :disabled="invalid || !notes"
                variant="primary"
                @click="cancelDetail(requestData)"
              >
                <b-spinner small v-if="isSaving" />Confirmar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-card-text>
  </b-modal>
</template>

<script>

export default {
  async mounted() {
    this.invalid = false;
  },
  props: {
    requestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invalid: false,
      isSaving: false,
      notes: null
    };
  },
  computed: {},
  methods: {
    async cancelDetail() {
      this.requestData.reviewNotes = structuredClone(this.notes)
      this.$emit("choose-response", ({request: this.requestData, choice: 4}))
      this.$root.$emit("bv::hide::modal", "modal-reject-maintenance-request-" + this.requestData.id);
    },
  },
};
</script>


